import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MtxPipesModule } from '@ng-matero/extensions/core';
import { MtxSelect, MtxSelectModule } from '@ng-matero/extensions/select';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

/**
 * This is just an example.
 */
@Component({
  selector: 'formly-field-mask',
  template: `<input
    matinput=""
    class="mat-mdc-input-element mat-mdc-form-field-input-control mdc-text-field__input ng-untouched ng-pristine ng-invalid cdk-text-field-autofill-monitored ng-star-inserted"
    ng-reflect-id="formly_10_input_rg_ie_6"
    type="text"
    ng-reflect-type="text"
    ng-reflect-required="true"
    ng-reflect-error-state-matcher="[object Object]"
    ng-reflect-form="[object Object]"
    ng-reflect-field="[object Object]"
    [formControl]="formControl"
    [placeholder]="props.placeholder!"
    [required]="props.required!"
    [mask]="props.mask"
    >`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, ReactiveFormsModule, MtxPipesModule, NgxMaskDirective],
  providers: [
    provideNgxMask(),
  ]
})
export class FormlyFieldInputMaskComponent extends FieldType<FieldTypeConfig> {

}
