import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, enableProdMode, importProvidersFrom, isDevMode } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';
import { NgxSpinnerModule } from "ngx-spinner";
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_CARD_CONFIG } from '@angular/material/card';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MtxMomentDatetimeModule } from '@ng-matero/extensions-moment-adapter';
import { MTX_DATETIME_FORMATS } from '@ng-matero/extensions/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask, provideNgxMask } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';

import { BASE_URL, appInitializerProviders, httpInterceptorProviders } from '@core';
import { environment } from '@env/environment';
import { PaginatorI18nService } from '@shared';
import { routes } from './app.routes';
import { FormlyConfigModule } from './shared/formly/formly-config.module';

import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from  '@ngrx/store-devtools' ;

import { messagesReducers } from './store/messages';
import { UsersReducers } from './store/users';
import { companysReducers } from './store/companys';

import { IConfig } from 'ngx-mask'
import { provideEffects } from '@ngrx/effects';
import { MessagesEffects } from './store/messages/messages.effects';

const maskConfig: Partial<IConfig> = {
  validation: false,
};


if (environment.production) {
  enableProdMode();
}

export function TranslateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideEnvironmentNgxMask(maskConfig),
    provideRouter(routes, withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
    }), withComponentInputBinding()),
    provideHttpClient(withInterceptorsFromDi()),

    importProvidersFrom(
      NgProgressHttpModule,
      NgProgressRouterModule,
      NgxPermissionsModule.forRoot(),
      ToastrModule.forRoot(),
      TranslateModule.forRoot({
          loader: {
              provide: TranslateLoader,
              useFactory: TranslateHttpLoaderFactory,
              deps: [HttpClient],
          },
      }),
      FormlyConfigModule.forRoot(),
      NgxMaskDirective,
      NgxMaskPipe,
      // You can also import the other adapter you need (e.g. luxon, date-fns)
      MatMomentDateModule,
      MtxMomentDatetimeModule,
      NgxSpinnerModule.forRoot({ type: 'ball-atom' })
    ),
    { provide: BASE_URL, useValue: environment.baseUrl },
    //
    // ==================================================
    ...httpInterceptorProviders,
    ...appInitializerProviders,
    {
        provide: MatPaginatorIntl,
        deps: [PaginatorI18nService],
        useFactory: (paginatorI18nSrv: PaginatorI18nService) => paginatorI18nSrv.getPaginatorIntl(),
    },
    {
        provide: MAT_DATE_LOCALE,
        useFactory: () => navigator.language, // <= This will be overrided by runtime setting
    },
    {
        provide: MAT_CARD_CONFIG,
        useValue: {
            appearance: 'outlined',
        },
    },
    {
        provide: MAT_DATE_FORMATS,
        useValue: {
            parse: {
                dateInput: 'DD-MM-YYYY',
            },
            display: {
                dateInput: 'DD-MM-YYYY',
                monthYearLabel: 'YYYY MMM',
                dateA11yLabel: 'LL',
                monthYearA11yLabel: 'YYYY MMM',
            },
        },
    },
    {
        provide: MTX_DATETIME_FORMATS,
        useValue: {
            parse: {
                dateInput: 'DD-MM-YYYY',
                yearInput: 'YYYY',
                monthInput: 'MMMM',
                datetimeInput: 'DD-MM-YYYY HH:mm',
                timeInput: 'HH:mm',
            },
            display: {
                dateInput: 'DD-MM-YYYY',
                yearInput: 'YYYY',
                monthInput: 'MMMM',
                datetimeInput: 'DD-MM-YYYY HH:mm',
                timeInput: 'HH:mm',
                monthYearLabel: 'YYYY MMMM',
                dateA11yLabel: 'LL',
                monthYearA11yLabel: 'MMMM YYYY',
                popupHeaderDateLabel: 'MMM DD, ddd',
            },
        },
    },
    provideAnimations(),
    provideStore({
      messages: messagesReducers,
      users: UsersReducers,
      companys: companysReducers,
    }),
    provideEffects([
      MessagesEffects,
    ]),
    provideStoreDevtools({
      maxAge : 25 , // Mantém os últimos 25 estados
      logOnly : ! isDevMode (), // Restringe a extensão ao modo somente log
      autoPause : true , // Pausa a gravação ações e mudanças de estado quando a janela de extensão não está aberta
      trace : false , // Se definido como true, incluirá o rastreamento de pilha para cada ação despachada, para que você possa vê-lo na guia trace saltando diretamente para aquela parte do código
      traceLimit : 75 , // máximo de quadros de rastreamento de pilha a serem armazenados (caso a opção de rastreamento tenha sido fornecida como verdadeira)
     }),
],
};
