@if (showHeader) {
  <div class="matero-sidebar-header">
    <app-branding></app-branding>
    @if (showToggle) {
      <mat-slide-toggle hideIcon
        [checked]="toggleChecked"
        (change)="toggleCollapsed.emit()">
      </mat-slide-toggle>
    }
  </div>
}

<div class="matero-sidebar-main scrollbar-none">
  @if (showUser) {
    <app-user-panel></app-user-panel>
  }
  <app-sidemenu [ripple]="showToggle"></app-sidemenu>
</div>
