import { Component } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-branding',
  template: `
    <a class="d-inline-block text-nowrap r-full text-reset" href="https://mngd.com.br/product" target="_blank" rel="noopener noreferrer">
      <img src="{{ imageLogo }}" class="brand-logo align-middle m-2 r-full" alt="logo" />
      <span class="align-middle f-s-16 f-w-500 m-x-8 hide-small">MySystem</span>
    </a>
  `,
  styles: [
    `
      .brand-logo {
        width: 30px;
        height: 30px;
      }

      a {
        text-decoration: none;
      }
    `,
  ],
  standalone: true,
})
export class BrandingComponent {

  constructor() {}

  resource = '/storage/logo.jpg';
  url = environment.APIbaseUrl + this.resource;
  imageLogo = this.url.replace('/api/', '/');
}
