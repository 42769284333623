<mat-toolbar class="matero-toolbar">
  @if (showToggle) {
    <button mat-icon-button (click)="toggleSidenav.emit()">
      <mat-icon>menu</mat-icon>
    </button>
  }

  @if (showBranding) {
    <app-branding></app-branding>
  }

  <span class="flex-fill"></span>

  <!--<app-github-button></app-github-button>

  <button mat-icon-button>
    <mat-icon>search</mat-icon>
  </button>
  -->
  <button mat-icon-button class="hide-small" (click)="toggleFullscreen()">
    <mat-icon>fullscreen</mat-icon>
  </button>

  <app-notification class="hide-small"></app-notification>

  <app-translate></app-translate>

  <app-user></app-user>
  <!--
  <button mat-icon-button class="hide-small" (click)="toggleSidenavNotice.emit()">
    <mat-icon>list</mat-icon>
  </button>
  -->
</mat-toolbar>
