<breadcrumb></breadcrumb>

<div #statistics class="row">
  <!-- Statistics -->
  @for (stat of stats; track stat) {
    <div class="col-sm-6 col-md-3">
      <mat-card [ngClass]="['text-white', stat.color]">
        <mat-card-header>
          <mat-card-subtitle class="text-white">{{stat.title}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <h2 class="m-y-8">Meta: {{stat.amount}}</h2>
          <mtx-progress [value]="stat.progress.value"
            height="2px"
            foreground="rgba(255,255,255,1)"
            background='rgba(0,0,0,.5)'>
          </mtx-progress>
          <small class="text-muted">Mês</small>
        </mat-card-content>
      </mat-card>
    </div>
  }



  <!-- Charts -->
  <div class="col-md-7">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Faturamento mensal</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div id="chart1"></div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-md-5">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Vendas na semana</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div id="chart2"></div>
      </mat-card-content>
    </mat-card>
  </div>


  <!-- Others
  <div class="col-md-6">
    <mat-card>
      <mat-tab-group>
        <mat-tab label="First">
          <div class="m-16">
            <img class="d-block w-full" src="assets/images/pixabay/2.jpg" alt="">
            <h3>Aenean viverra arcu nec pellentesque ultrices. In erat purus, adipiscing nec
              lacinia
              at, ornare ac eros.
            </h3>
            <p>
              Nullam at risus metus. Quisque nisl purus, pulvinar ut mauris vel, elementum
              suscipit
              eros. Praesent
              ornare ante massa, egestas pellentesque orci convallis ut. Curabitur consequat
              convallis est, id luctus
              mauris lacinia vel. Nullam tristique lobortis mauris, ultricies fermentum lacus
              bibendum id. Proin non
              ante tortor. Suspendisse pulvinar ornare tellus nec pulvinar. Nam pellentesque
              accumsan mi, non
            pellentesque sem convallis sed. Quisque rutrum erat id auctor gravida.</p>
          </div>
        </mat-tab>
        <mat-tab label="Second">
          <div class="m-16">
            <img class="d-block w-full" src="assets/images/pixabay/8.jpg" alt="">
            <h3>Vestibulum vitae diam nec odio dapibus placerat. Ut ut lorem justo.</h3>
            <p>
              Fusce bibendum augue nec fermentum tempus. Sed laoreet dictum tempus. Aenean ac sem
              quis nulla malesuada
              volutpat. Nunc vitae urna pulvinar velit commodo cursus. Nullam eu felis quis diam
              adipiscing hendrerit
              vel ac turpis. Nam mattis fringilla euismod. Donec eu ipsum sit amet mauris iaculis
              aliquet. Quisque sit
              amet feugiat odio. Cras convallis lorem at libero lobortis, placerat lobortis sapien
              lacinia. Duis sit
            amet elit bibendum sapien dignissim bibendum.</p>
          </div>
        </mat-tab>
        <mat-tab label="Third">
          <div class="m-16">
            <img class="d-block w-full" src="assets/images/pixabay/16.jpg" alt="">
            <h3>Maecenas eget turpis luctus, scelerisque arcu id, iaculis urna. Interdum et
              malesuada fames ac ante
            ipsum primis in faucibus.</h3>
            <p>
              Morbi placerat est nec pharetra placerat. Ut laoreet nunc accumsan orci aliquam
              accumsan. Maecenas
              volutpat dolor vitae sapien ultricies fringilla. Suspendisse vitae orci sed nibh
              ultrices tristique.
              Aenean in ante eget urna semper imperdiet. Pellentesque sagittis a nulla at
              scelerisque. Nam augue nulla,
              accumsan quis nisi a, facilisis eleifend nulla. Praesent aliquet odio non imperdiet
            fringilla.</p>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card>

    <mat-card>
      <mat-card-header>
        <mat-card-title>Album</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-grid-list cols="2">
          <mat-grid-tile class="bg-red-50">1</mat-grid-tile>
          <mat-grid-tile class="bg-purple-50">2</mat-grid-tile>
          <mat-grid-tile class="bg-orange-50">3</mat-grid-tile>
          <mat-grid-tile class="bg-cyan-50">4</mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>
    </mat-card>
  </div>
  -->
<!--
  <div class="col-md-6">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Avatar List</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-list>
          @for (message of messages; track message) {
            <mat-list-item>
              <img matListItemAvatar [src]="message.img" alt="Avatar">
              <h3 matListItemTitle>{{message.subject}}</h3>
              <p matListItemLine>
                <span>{{message.content}} </span>
              </p>
            </mat-list-item>
          }
        </mat-list>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-header>
        <mat-card-title>Revenue Table</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <table class="w-full" mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef> No. </th>
            <td mat-cell *matCellDef="let element;"> {{element.position}} </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element;"> {{element.name}} </td>
          </ng-container>
          <ng-container matColumnDef="weight">
            <th mat-header-cell *matHeaderCellDef> Weight </th>
            <td mat-cell *matCellDef="let element;"> {{element.weight}} </td>
          </ng-container>
          <ng-container matColumnDef="symbol">
            <th mat-header-cell *matHeaderCellDef> Symbol </th>
            <td mat-cell *matCellDef="let element;"> {{element.symbol}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </mat-card-content>
    </mat-card>


    <mat-card>
      <mat-card-header>
        <mat-card-title>Chips & Buttons</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-chip-listbox class="m-t-16">
          <mat-chip-option selected>One fish</mat-chip-option>
          <mat-chip-option color="primary">Primary fish</mat-chip-option>
          <mat-chip-option color="accent">Accent fish</mat-chip-option>
          <mat-chip-option color="warn">Two fish</mat-chip-option>
        </mat-chip-listbox>
        <mat-chip-listbox class="m-t-8">
          <mat-chip-option class="bg-orange-300">Orange fish</mat-chip-option>
          <mat-chip-option class="bg-teal-300">Teal fish</mat-chip-option>
          <mat-chip-option class="bg-cyan-300">Cyan fish</mat-chip-option>
          <mat-chip-option class="bg-purple-300" selected>Purple fish</mat-chip-option>
          <mat-chip-option class="bg-green-300">Green fish</mat-chip-option>
        </mat-chip-listbox>

        <div class="m-t-16">
          <button mat-raised-button>Basic</button>
          <button mat-raised-button color="primary">Primary</button>
          <button mat-raised-button color="accent">Accent</button>
          <button mat-raised-button color="warn">Warn</button>
          <button mat-raised-button disabled>Disabled</button>
          <a mat-raised-button routerLink=".">Link</a>
        </div>
        <div class="m-t-8">
          <button mat-raised-button class="bg-orange-500 text-white">Orange</button>
          <button mat-raised-button class="bg-teal-500 text-white">Teal</button>
          <button mat-raised-button class="bg-cyan-500 text-white">Cyan</button>
          <button mat-raised-button class="bg-purple-500 text-white">Purple</button>
          <button mat-raised-button class="bg-green-500 text-white">Green</button>
        </div>
      </mat-card-content>
    </mat-card>

  </div>-->

</div>
